/* OTPInput.css */

.otp-input-container {
    display: flex;
    justify-content: space-between;
  }
  
  .otp-input-container input {
    width: 40px;
    height: 40px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    outline: none;
  }
  
  /* Style for the last input to remove margin */
  input:last-child {
    margin-right: 0;
  }
  