.login-box input{
    border-radius: 0;
}
.login-box .sendOTP{
    background-color: #11760e;
    color: white;
    width: 90%;
    font-size: 13px;
    font-weight: 600;
    border-radius: 0;
    line-height: 0;
    min-height: 35px;
    
}
.login-box h2{
    color: #e1e8de;
}
.login-box .RadeemBox{
    padding: 5px;
    border: 3px solid #C0C0C0;
}
.login-box .RadeemBox .detailsHeading h6{
    color:  #ee1a1a;
    /* color: #ADD8E6; */
    font-weight: 0;
    text-align: center;
    margin: 0 0 15px;
}
.login-box .detailsCode h1{
    margin: 0 0 15px;
    padding: 0;
    color:#FF6F61 ;
    text-align: center;
    font-weight: 900;
    letter-spacing: 3px
}
.login-box .detailsPhoneNumber h2{
    margin: 0 0 15px;
    padding: 0;
    color: #d2ac67;
    text-align: center;
    font-weight: 800
}
.login-box .detailsDate h5{
    margin: 0 0 15px;
    padding: 0;
    color: #6c757d;
    text-align: center;
    font-weight: 800
}
.login-box .OTPheading{
    color: #6c757d;
}
.login-box .ClearRadeem{
    color: white;
    background-color: #ee1a1a;
    width: 80%;
    font-size: 13px;
    font-weight: 600;
    border-radius: 0;
    line-height: 0;
    min-height: 35px;
}
.login-box .RadeemButton{
    color: #282c34;
    background-color: #d2ac67;
    width: 80%;
    font-size: 15px;
    font-weight: 700;
    border-radius: 0;
    line-height: 0;
    min-height: 35px;
}
.login-box .detailsName h5{
    margin: 0 0 15px;
    padding: 0;
    color: #ADD8E6;
    text-align: center;
    font-weight: 800
}
.login-box .RadeemBox .detailsHouseName p{
    font-weight: 0;
    text-align: center;
    margin: 0 0 15px;
}