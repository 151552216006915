/* Default styles */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.pagination {
  background-color: #f8f9fa;
  display: inline-flex; /* Added to keep pagination items on the same line */
}

.pagination > li > a,
.pagination > li > span {
  color: #007bff;
  border: 1px solid #dee2e6;
  margin: 2px;
  padding: 8px 12px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination > .active > a,
.pagination > .active > span {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:hover {
  color: #6c757d;
  pointer-events: none;
}

.pagination > li > a:hover,
.pagination > li > span:hover {
  background-color: #e9ecef;
}

.pagination-prev-next {
  font-size: 18px;
  font-weight: bold;
}
.pagination-ellipsis {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  border: 1px solid #dee2e6;
  margin: 2px;
  padding: 8px 12px;
  font-weight: bold;
  background-color: #f8f9fa;
  pointer-events: none;
}
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .pagination-container {
    flex-direction: column;
    margin: 10px; /* Adjust margin for smaller screens */
  }

  .pagination {
    flex-direction: row; /* Keep pagination items on the same line for smaller screens */
    justify-content: center; /* Center items horizontally */
  }
}
